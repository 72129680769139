import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useAuth } from '@teron/fabric';

import { useSearchContext } from './SearchProvider';
import createClient from '../api';

const Context = createContext({});

const PropertyProvider = ({ children }) => {
  const { token } = useAuth();
  const client = createClient({ token });
  const {
    navigateTo,
    registryId,
    address,
    setAddress,
  } = useSearchContext();
  const [addresses, setAddresses] = useState([]);
  const [property, setProperty] = useState();

  const designation = useMemo(() => {
    if (property) {
      const des = property.registerbeteckning;
      const desStr = `${des.registeromrade} ${des.traktnamn} ${des.block}:${des.enhet}`;
      return desStr.replace('*:', '');
    }
    return null;
  }, [property]);

  const resetProperty = () => {
    setProperty();
    setAddresses([]);
  };

  useEffect(() => {
    const getProperty = async (id) => {
      try {
        const data = await client.propertiesDetail(id);
        setProperty(data);
      } catch (e) {
        navigateTo('');
      }
    };

    if (registryId) {
      getProperty(registryId);
    }
  }, [registryId]);

  useEffect(() => {
    const getRegistryId = async (addr) => {
      const data = await client.properties(addr);
      navigateTo(data[0]);
    };

    if (!registryId && address) {
      getRegistryId(address);
    }
  }, [address]);

  useEffect(() => {
    const getPropertyAddresses = async (id) => {
      const data = await client.propertiesAddresses(id);
      setAddresses(data);

      const addrStr = Object.keys(data[0])
        .map((key) => data[0][key])
        .join(' ');
      setAddress(addrStr);
    };

    if (property && addresses.length === 0) {
      getPropertyAddresses(property.objektidentitet);
    }
  }, [property]);

  return (
    <Context.Provider
      value={{
        property,
        designation,
        addresses,
        resetProperty,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const usePropertyContext = () => useContext(Context);

export default PropertyProvider;
export { usePropertyContext };
