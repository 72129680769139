import React, { useState } from 'react';
import { render } from 'react-dom';
import styled from '@emotion/styled';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink as Link,
} from 'react-router-dom';

import {
  App,
  Navlink,
  Icon,
  Grid,
  Cell,
  VerticalNavbar,
  useTheme,
} from '@teron/fabric';

import SearchProvider from './providers/SearchProvider';
import PlaceProvider from './providers/PlaceProvider';
import PropertyProvider from './providers/PropertyProvider';
import WorkplaceProvider from './providers/WorkplaceProvider';
import PoiProvider from './providers/PoiProvider';
import RentsProvider from './providers/RentsProvider';
import DistanceProvider from './providers/DistanceProvider';
import MapProvider from './providers/MapProvider';
import DashboardView from './views/DashboardView';

const authConfig = {
  realm: 'mogul',
  url: 'https://keycloak.trell.tech/auth/',
  clientId: 'dev',
  scope: 'dev',
  enableLogging: true,
};

const StyledToggler = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  margin-left: 0.4rem;
  width: 100%;
`;

const Sidebar = ({ wide, toggleWide }) => {
  const { toggleTheme, theme } = useTheme();
  const [contract, setContract] = useState(true);

  const toggleCompact = () => setContract(!contract);

  const toggler = (isActive) => ({ children, ...props }) => <StyledToggler {...props}>
    {children} <Icon icon={isActive ? 'toggle-on' : 'toggle-off'} />
  </StyledToggler>;

  return (
    <VerticalNavbar contract={contract}>
      <Grid columns="1fr auto" align="top" vertical style={{ height: '100%' }}>
        <Cell>
          <Navlink exact component={Link} to="/" icon="home" title="Överblick" />
        </Cell>
        <Cell>
          <Navlink
            titleComponent={toggler(wide)}
            onClick={toggleWide}
            icon="photo-size_select_large"
            title="Full width"
          />
          <Navlink
            titleComponent={toggler(!contract)}
            onClick={toggleCompact}
            icon="burst-mode"
            title="Static sidebar"
          />
          <Navlink
            titleComponent={toggler(theme.name === 'default-dark-theme')}
            onClick={toggleTheme}
            icon="timelapse"
            title="Night mode"
          />
        </Cell>
      </Grid>
    </VerticalNavbar>
  );
};

const Wrapper = () => {
  const [wide, setWide] = useState(true);
  const toggleWide = () => setWide(!wide);

  return (
    <Router>
      <App themeName="default-light-theme" authConfig={authConfig}>
        <SearchProvider>
          <PlaceProvider>
            <PropertyProvider>
              <WorkplaceProvider>
                <RentsProvider>
                  <PoiProvider>
                    <DistanceProvider>
                      <MapProvider>
                        <Sidebar wide={wide} toggleWide={toggleWide} />
                        <Switch>
                          <Route path="/" exact>
                            <DashboardView wide={wide} />
                          </Route>
                          <Route path="/:registryId">
                            <DashboardView wide={wide} />
                          </Route>
                        </Switch>
                      </MapProvider>
                    </DistanceProvider>
                  </PoiProvider>
                </RentsProvider>
              </WorkplaceProvider>
            </PropertyProvider>
          </PlaceProvider>
        </SearchProvider>
      </App>
    </Router>
  );
};

render(<Wrapper />, document.getElementById('app'));
