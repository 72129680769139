import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '@teron/fabric';

const StyledSelect = styled.select`
  width: 100%;
  height: 2.2rem;
  color: ${(props) => props.theme.context.background.contrast};
  background: ${(props) => props.theme.context.background.base};
  border: none;
  border-radius: 3px;
  margin-bottom: ${(props) => props.theme.spacing[props.margin] || 0};
`;

const Select = ({
  placeholder,
  options,
  valueKey,
  textKey,
  onChange,
  margin = 'standard',
}) => {
  const { theme } = useTheme('dark');

  return <>
    <StyledSelect
      theme={theme}
      margin={margin}
      onChange={(e) => onChange(e.target.value)}
    >
      {placeholder && <option key="placeholder">{placeholder}</option>}
      {options.map((option) => (
        <option
          key={option[valueKey]}
          value={option[valueKey]}>{option[textKey]}</option>
      ))}
    </StyledSelect>
  </>;
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  valueKey: PropTypes.string,
  textKey: PropTypes.string,
};

Select.defaultProps = {
  valueKey: 'value',
  textKey: 'text',
};

export default Select;
