import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';

import { WmsMapType } from '@googlemaps/ogc';

const STHLM_API_KEY = '9603342c-bd11-4f8c-8d90-e831dce6f6de';

const Context = createContext({});

const MapProvider = ({ children }) => {
  const mapRef = useRef();
  const [googleMaps, setGoogleMaps] = useState();
  const [layers, setLayers] = useState({});
  const [selectedLayers, setSelectedLayers] = useState([]);

  const resetMapLayers = () => {
    setSelectedLayers([]);
    Object.keys(layers).forEach((key) => layers[key].hide());
  };

  const hideOverlayLayer = (layer) => {
    const layerIndex = mapRef.current.overlayMapTypes.getArray().indexOf(layer);
    if (layerIndex !== -1) {
      mapRef.current.overlayMapTypes.removeAt(layerIndex);
    }
  };

  useEffect(() => {
    if (typeof googleMaps !== 'undefined') {
      const noiseLayer = WmsMapType({
        url: 'http://kartor.miljo.stockholm.se/geoserver/wms',
        layers: 'mfraster:bullerkartan-2012-vagtag2mleq24h',
        maxZoom: 18,
      });

      const parkingAllowedLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'ltfr:LTFR_P_TILLATEN',
        maxZoom: 18,
      });

      const parkingPurposeLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'ltfr:LTFR_LASTZON',
        maxZoom: 18,
      });

      const parkingTariffLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'ltfr:LTFR_TAXA_VIEW',
        maxZoom: 18,
      });

      const cityBikesLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'od_gis:CityBikes_Punkt',
        maxZoom: 18,
      });

      const trafficFlowBicycleLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'od_gis:Trafikflode_Cykel',
        maxZoom: 18,
      });

      const trafficFlowMotorLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'od_gis:Trafikflode_Motorfordon',
        maxZoom: 18,
      });

      const trafficFlowPedestrianLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'od_gis:Trafikflode_Gang',
        maxZoom: 18,
      });

      const trafficFlowYearLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'od_gis:Arstrafik',
        maxZoom: 18,
      });

      const accidentsLayer = WmsMapType({
        url: `http://openstreetgs.stockholm.se/geoservice/api/${STHLM_API_KEY}/wms`,
        layers: 'od_gis:Trafikolycka_Punkt',
        maxZoom: 18,
      });

      const trafficLayer = new googleMaps.TrafficLayer();
      const bicyclingLayer = new googleMaps.BicyclingLayer();
      const transitLayer = new googleMaps.TransitLayer();

      setLayers({
        parkingAllowed: {
          name: 'Parkering Tillåten',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(parkingAllowedLayer),
          hide: () => hideOverlayLayer(parkingAllowedLayer),
        },
        parkingPurpose: {
          name: 'Parkering Ändamålsplats',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(parkingPurposeLayer),
          hide: () => hideOverlayLayer(parkingPurposeLayer),
        },
        parkingTariff: {
          name: 'Parkering Taxa',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(parkingTariffLayer),
          hide: () => hideOverlayLayer(parkingTariffLayer),
        },
        cityBikes: {
          name: 'CityBikes',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(cityBikesLayer),
          hide: () => hideOverlayLayer(cityBikesLayer),
        },
        trafficFlowPedestrian: {
          name: 'Trafikflöde Gång',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(trafficFlowPedestrianLayer),
          hide: () => hideOverlayLayer(trafficFlowPedestrianLayer),
        },
        trafficFlowBicycle: {
          name: 'Trafikflöde Cykel',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(trafficFlowBicycleLayer),
          hide: () => hideOverlayLayer(trafficFlowBicycleLayer),
        },
        trafficFlowMotor: {
          name: 'Trafikflöde Motorfordon',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(trafficFlowMotorLayer),
          hide: () => hideOverlayLayer(trafficFlowMotorLayer),
        },
        trafficFlowYear: {
          name: 'Årstrafik',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(trafficFlowYearLayer),
          hide: () => hideOverlayLayer(trafficFlowYearLayer),
        },
        noise: {
          name: 'Bullernivåer',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(noiseLayer),
          hide: () => hideOverlayLayer(noiseLayer),
        },
        accidents: {
          name: 'Trafikolyckor',
          source: 'Sthlm Stad',
          show: () => mapRef.current.overlayMapTypes.push(accidentsLayer),
          hide: () => hideOverlayLayer(accidentsLayer),
        },
        trafficFlowMotorGoogle: {
          name: 'Trafikflöde Motorfordon',
          source: 'Google',
          show: () => trafficLayer.setMap(mapRef.current),
          hide: () => trafficLayer.setMap(null),
        },
        bicycling: {
          name: 'Cykelvägar',
          source: 'Google',
          show: () => bicyclingLayer.setMap(mapRef.current),
          hide: () => bicyclingLayer.setMap(null),
        },
        transit: {
          name: 'Kommunaltrafik',
          source: 'Google',
          show: () => transitLayer.setMap(mapRef.current),
          hide: () => transitLayer.setMap(null),
        },
      });
    }
  }, [googleMaps]);

  return (
    <Context.Provider
      value={{
        layers,
        selectedLayers,
        setSelectedLayers,
        resetMapLayers,
        googleMaps,
        setGoogleMaps,
        mapRef,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useMapContext = () => useContext(Context);

export default MapProvider;
export { useMapContext };
