import React from 'react';

import { Button } from '@teron/fabric';

import { ExportToCsv } from 'export-to-csv';

import { usePlaceContext } from '../providers/PlaceProvider';
import { usePropertyContext } from '../providers/PropertyProvider';
import { useWorkplaceContext } from '../providers/WorkplaceProvider';
import { usePoiContext } from '../providers/PoiProvider';
import { useRentsContext } from '../providers/RentsProvider';
import { useDistanceContext } from '../providers/DistanceProvider';

const CsvExport = () => {
  const { place } = usePlaceContext();
  const { pois } = usePoiContext();
  const { distances } = useDistanceContext();
  const { workplaces, workplaceKeys } = useWorkplaceContext();
  const { designation, addresses } = usePropertyContext();
  const {
    valueAreas,
    valueArea,
    rents,
  } = useRentsContext();

  const exportable = () => (
    !place.dirty
    && typeof designation !== 'undefined'
    && typeof rents !== 'undefined'
  );

  const csvOptions = (title) => ({
    filename: `${designation}_${title}`.toLowerCase(),
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });

  const exportProperty = () => {
    const csvExporter = new ExportToCsv(csvOptions('info'));

    const info = addresses.map((address) => ({
      'adress': ['route', 'street_number', 'letter']
        .filter((key) => typeof address[key] !== 'undefined')
        .map((key) => address[key])
        .join(' '),
      'postnummer': address.postal_code,
      'postort': address.postal_town,
      'fastighetsbeteckning': '',
      'värdeområde_kod': '',
      'värdeområde_namn': '',
      'genomsnittshyra': '',
    })).sort((a, b) => {
      if (a.adress < b.adress) {
        return -1;
      }
      if (a.adress > b.adress) {
        return 1;
      }
      return 0;
    });

    info[0] = {
      ...info[0],
      'fastighetsbeteckning': designation,
      'värdeområde_kod': valueArea,
      'värdeområde_namn': valueAreas.find((va) => va.code === valueArea).name,
      'genomsnittshyra': `${rents['värdeår 2016']} kr/m2`,
    };

    csvExporter.generateCsv(info);
  };

  const exportWorkplaces = () => {
    const csvExporter = new ExportToCsv(csvOptions('arbetsställen'));

    const cleanedWps = workplaces.map((wp) => (
      Object.keys(wp).reduce((acc, curr) => {
        if (workplaceKeys.includes(curr)) {
          acc[curr] = wp[curr];
        }
        return acc;
      }, {})
    ));

    if (cleanedWps.length !== 0) {
      csvExporter.generateCsv(cleanedWps);
    }
  };

  const exportPois = () => {
    const csvExporter = new ExportToCsv(csvOptions('i_närheten'));

    const flattenedPois = Object.keys(pois).reduce((acc, cur) => {
      pois[cur].places.forEach((poi, i) => {
        let distance = '';
        let duration = '';
        const distObj = distances[cur].distances[i];
        if (typeof distObj !== 'undefined') {
          distance = distObj.distance.text;
          duration = `${distObj.duration.text} ${distances[cur].translation}`;
        }
        acc.push({
          namn: poi.name,
          omnejd: poi.vicinity,
          typ: pois[cur].translation,
          'avstånd': distance,
          'restid': duration,
        });
      });
      return acc;
    }, []);

    if (flattenedPois.length !== 0) {
      csvExporter.generateCsv(flattenedPois);
    }
  };

  const exportCsvs = () => {
    exportProperty();
    exportWorkplaces();
    exportPois();
  };

  return (
    <Button
      disabled={!exportable()}
      appearance="primary"
      onClick={exportCsvs}>Exportera till csv</Button>
  );
};

export default CsvExport;
