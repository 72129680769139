import React from 'react';

import {
  Page,
  PageContent,
  Container,
  Grid,
  Cell,
  Panel,
  ListItem,
  DonutMarker,
} from '@teron/fabric';

import Addresses from '../components/Addresses';
import CsvExport from '../components/CsvExport';
import Map from '../components/Map';
import MapLayers from '../components/MapLayers';
import Pois from '../components/Pois';
import SearchForm from '../components/SearchForm';
import ValueAreas from '../components/ValueAreas';
import Workplaces from '../components/Workplaces';
import { usePlaceContext } from '../providers/PlaceProvider';
import { usePoiContext } from '../providers/PoiProvider';
import { usePropertyContext } from '../providers/PropertyProvider';
import { useRentsContext } from '../providers/RentsProvider';
import { useWorkplaceContext } from '../providers/WorkplaceProvider';
import { useSearchContext } from '../providers/SearchProvider';
import { useMapContext } from '../providers/MapProvider';
import { useDistanceContext } from '../providers/DistanceProvider';

const DashboardView = ({ wide }) => {
  const { resetSearch } = useSearchContext();
  const { resetWorkplaces } = useWorkplaceContext();
  const { resetProperty, addresses, designation } = usePropertyContext();
  const { valueAreas } = useRentsContext();
  const { resetPois } = usePoiContext();
  const { resetMapLayers } = useMapContext();
  const { resetDistances } = useDistanceContext();
  const {
    place,
    resetPlace,
  } = usePlaceContext();

  const reset = () => {
    resetSearch();
    resetPlace();
    resetProperty();
    resetWorkplaces();
    resetPois();
    resetMapLayers();
    resetDistances();
  };

  return (
    <Page wide={wide}>
      <PageContent expand>
        <Grid columns={12}>
          <Cell width={4} style={{ overflow: 'auto' }}>
            <Container>
              <Container>
                <Panel compact padding="compact">
                  <SearchForm reset={reset}>
                    {designation
                      ? <ListItem before={
                        <DonutMarker
                          value={100}
                          appearance="danger" />
                        }>
                        {designation}
                      </ListItem> : null
                    }
                  </SearchForm>
                </Panel>
              </Container>
              <Container>
                <Panel compact padding="compact">
                  <CsvExport />
                </Panel>
              </Container>
              {addresses.length !== 0
              && <Container>
                <Panel compact padding="compact">
                  <Addresses />
                </Panel>
              </Container>
              }
              {!place.dirty
                && <Container>
                  <Panel compact padding="compact">
                    <MapLayers />
                  </Panel>
                </Container>
              }
              {valueAreas.length !== 0
              && <Container>
                <Panel compact padding="compact">
                  <ValueAreas />
                </Panel>
              </Container>
              }
              <Container>
                <Panel compact padding="compact">
                  <Workplaces />
                </Panel>
              </Container>
              <Container>
                <Panel compact padding="compact">
                  <Pois />
                </Panel>
              </Container>
            </Container>
          </Cell>
          <Cell width={8}>
            <Panel compact padding="compact">
              <Map />
            </Panel>
          </Cell>
        </Grid>
      </PageContent>
    </Page>
  );
};

export default DashboardView;
