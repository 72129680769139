import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '@teron/fabric';

const StyledInput = styled.input`
  cursor: pointer;
  margin-right: ${(props) => props.theme.spacing.minimal};
  margin-top: -0.4rem;
  vertical-align: middle;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  margin-right: ${(props) => props.theme.spacing.compact};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  margin-bottom: ${(props) => (props.inline ? '0' : props.theme.spacing.compact)};
`;

const InputButton = React.forwardRef((props, ref) => {
  const {
    type,
    label,
    checked,
    onChange,
    inline,
  } = props;

  const { theme } = useTheme('dark');

  return <>
    <StyledLabel inline={inline} theme={theme}>
      <StyledInput
        ref={ref}
        theme={theme}
        type={type}
        checked={checked}
        onChange={onChange}
        {...props} />
       {label}
    </StyledLabel>
  </>;
});

InputButton.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
};

InputButton.defaultProps = {
  inline: true,
};

export default InputButton;
