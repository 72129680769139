import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useAuth } from '@teron/fabric';

import { useSearchContext } from './SearchProvider';
import createClient from '../api';
import { reduceAddressComponents } from '../utils';

const Context = createContext({});

const initialPlace = {
  dirty: true,
  address_components: [],
  geometry: {
    location: {
      lat: 59.3304352,
      lng: 18.0710674,
    },
  },
};

const PlaceProvider = ({ children }) => {
  const { token } = useAuth();
  const client = createClient({ token });
  const { placeId, address, setAddress } = useSearchContext();
  const [place, setPlace] = useState(initialPlace);

  const resetPlace = () => {
    setPlace(initialPlace);
  };

  useEffect(() => {
    const getPlace = async (id) => {
      const data = await client.placesDetail(id);
      setPlace(data.result);
    };

    if (placeId) {
      getPlace(placeId);
    }
  }, [placeId]);

  useEffect(() => {
    const getGeocode = async (addr) => {
      const data = await client.placesGeocode(addr);
      setPlace(data[0]);
    };

    if (!placeId && address) {
      getGeocode(address);
    }
  }, [address]);

  const addressComponents = useMemo(() => {
    if (!place.dirty) {
      return reduceAddressComponents(place);
    }
    return {};
  }, [place]);

  const isCompleteAddress = useMemo(() => (
    [
      'route',
      'street_number',
      'postal_code',
      'postal_town',
    ].every((key) => (typeof addressComponents[key] !== 'undefined'))
  ), [addressComponents]);

  useEffect(() => {
    if (isCompleteAddress) {
      setAddress([
        addressComponents['route'],
        addressComponents['street_number'],
        addressComponents['postal_code'].replace(/\s+/g, ''),
        addressComponents['postal_town'],
      ].join(' '));
    }
  }, [isCompleteAddress]);

  return (
    <Context.Provider
      value={{
        isCompleteAddress,
        addressComponents,
        place,
        resetPlace,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const usePlaceContext = () => useContext(Context);

export default PlaceProvider;
export { usePlaceContext };
