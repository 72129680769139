import React, {
  createContext,
  useReducer,
  useState,
  useContext,
  useEffect,
} from 'react';

import { useAuth } from '@teron/fabric';

import createClient from '../api';
import { usePlaceContext } from './PlaceProvider';

const Context = createContext();

const fetchStates = {
  NOT_FETCHED: 'notFetched',
  FETCH: 'fetch',
  FETCHING: 'fetching',
  FETCHED: 'fetched',
};

const initialPoiState = {
  parking: {
    radius: 500,
    translation: 'parkering',
    places: [],
  },
  subway_station: {
    radius: 500,
    translation: 'tunnelbana',
    places: [],
  },
  bus_station: {
    radius: 500,
    translation: 'buss',
    places: [],
  },
  train_station: {
    radius: 1000,
    translation: 'tåg',
    places: [],
  },
  restaurant: {
    radius: 500,
    translation: 'restaurang',
    places: [],
  },
  gym: {
    radius: 500,
    translation: 'träning',
    places: [],
  },
  lodging: {
    radius: 500,
    translation: 'hotell',
    places: [],
  },
  school: {
    radius: 500,
    translation: 'skola',
    places: [],
  },
  airport: {
    radius: 50000,
    maxHits: 2,
    translation: 'flygplats',
    places: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_POIS': {
      const { poiType, pois } = action;

      const maxHits = state[poiType].maxHits || pois.length;

      return {
        ...state,
        [poiType]: {
          ...state[poiType],
          places: pois.slice(0, maxHits),
        },
      };
    }
    case 'RESET_POIS': {
      return initialPoiState;
    }
    default: {
      return state;
    }
  }
};

const PoiProvider = ({ children }) => {
  const { token } = useAuth();
  const client = createClient({ token });
  const { place } = usePlaceContext();
  const [fetchState, setFetchState] = useState(fetchStates.NOT_FETCHED);
  const [state, dispatch] = useReducer(reducer, initialPoiState);
  const [activeType, setActiveType] = useState();
  const [activePoi, setActivePoi] = useState();

  const addPois = (poiType, pois) => dispatch({ type: 'ADD_POIS', poiType, pois });
  const resetPois = () => {
    setFetchState(fetchStates.NOT_FETCHED);
    setActiveType();
    setActivePoi();
    dispatch({ type: 'RESET_POIS' });
  };

  const fetchPois = () => setFetchState(fetchStates.FETCH);
  const fetchable = !place.dirty;

  const { location } = place.geometry;

  useEffect(() => {
    const _fetchPois = async (type) => {
      const locationStr = `${location.lat},${location.lng}`;
      const { radius } = state[type];
      const data = await client.placesNearby(locationStr, type, radius);
      addPois(type, data);
      setFetchState(fetchStates.FETCHED);
    };

    if (fetchState === fetchStates.FETCH && fetchable) {
      setFetchState(fetchStates.FETCHING);
      Object.keys(state).forEach((type) => _fetchPois(type));
    }
  }, [fetchState]);

  return (
    <Context.Provider
      value={{
        pois: state,
        fetchable,
        fetchPois,
        fetchState,
        fetchStates,
        activeType,
        setActiveType,
        activePoi,
        setActivePoi,
        resetPois,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const usePoiContext = () => useContext(Context);

export default PoiProvider;
export { usePoiContext };
