import React from 'react';
import PropTypes from 'prop-types';

import { humanDistance } from '../utils';
import AccordionSection from './AccordionSection';

const Accordion = ({
  sections,
  activeSection,
  onSectionClick,
  activeItem,
  onItemClick,
}) => <>
  {Object.keys(sections).map((key) => (
    !!sections[key].places.length
    && <AccordionSection
      key={key}
      sectionKey={key}
      title={sections[key].translation}
      subTitle={`inom ${humanDistance(sections[key].radius)}`}
      items={sections[key].places}
      activeSection={activeSection}
      onSectionClick={onSectionClick}
      activeItem={activeItem}
      onItemClick={onItemClick} />
  ))}
</>;

Accordion.propTypes = {
  sections: PropTypes.shape({}).isRequired,
  activeSection: PropTypes.string,
  onSectionClick: PropTypes.func,
  activeItem: PropTypes.string,
  onItemClick: PropTypes.func,
};

export default Accordion;
