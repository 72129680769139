import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';

import { useAuth } from '@teron/fabric';

import createClient from '../api';
import { usePropertyContext } from './PropertyProvider';

const Context = createContext({});

const RentsProvider = ({ children }) => {
  const { token } = useAuth();
  const client = createClient({ token });
  const { property } = usePropertyContext();
  const [valueAreas, setValueAreas] = useState([]);
  const [valueArea, setValueArea] = useState();
  const [rents, setRents] = useState();

  useEffect(() => {
    const getValueAreas = async () => {
      const propAttr = property.fastighetsattribut;
      const municipalityCode = `${propAttr.lankod}${propAttr.kommunkod}`;
      const data = await client.valueAreas(municipalityCode);
      setValueAreas(data);
    };

    if (property) {
      getValueAreas();
    } else {
      setValueAreas([]);
      setValueArea();
    }
  }, [property]);

  useEffect(() => {
    const getRents = async () => {
      const data = await client.rents(valueArea);
      setRents(data.results[0]);
    };

    if (valueArea) {
      getRents();
    } else {
      setRents();
    }
  }, [valueArea]);

  return (
    <Context.Provider
      value={{
        valueAreas,
        valueArea,
        setValueArea,
        rents,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useRentsContext = () => useContext(Context);

export default RentsProvider;
export { useRentsContext };
