import React, { useState } from 'react';
import styled from '@emotion/styled';

import {
  Button,
  Panel,
  Title,
  ListItem,
  Text,
  Icon,
  useTheme,
} from '@teron/fabric';

import { titleCaseAB } from '../utils';
import { usePlaceContext } from '../providers/PlaceProvider';
import { useWorkplaceContext } from '../providers/WorkplaceProvider';

const StyledListItem = styled(ListItem)`
  cursor: ${(props) => (typeof props.onClick !== 'undefined' ? 'pointer' : 'inherit')};
  align-items: baseline;
`;

const StyledText = styled(Text)`
  &:hover {
    color: ${(props) => props.theme.context.primary.base};
  }
`;

const Workplaces = () => {
  const { theme } = useTheme();
  const { isCompleteAddress } = usePlaceContext();
  const {
    fetchState,
    fetchStates,
    fetchWorkplaces,
    workplaceCount,
    workplaces,
    activeWp,
    setActiveWp,
    workplaceKeys,
  } = useWorkplaceContext();

  workplaces.sort((a, b) => {
    const aName = a['Företagsnamn'];
    const bName = b['Företagsnamn'];
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  const detailFilter = (wp, key) => (
    wp[key] !== '' && wp[key] !== '*' && key !== 'Företagsnamn'
  );

  const detailComponent = (wp) => (
    <>
      <Title actions={<Icon icon="highlight-off" onClick={() => setActiveWp()} />}>
        {titleCaseAB(wp['Företagsnamn'])}
      </Title>
      <Panel compact padding="compact">
        {workplaceKeys
          .filter((key) => detailFilter(wp, key))
          .map((key) => (
            <div key={key} style={{ marginBottom: '0.8rem' }}>
              <Text
                appearance="textMuted"
                style={{ marginBottom: '0.2rem', fontSize: '0.9rem' }}
              >{key.replace('_', ' ')}</Text>
              <Text weight="medium">{wp[key]}</Text>
            </div>
          ))
        }
      </Panel>
    </>
  );

  const listComponent = (wps) => (
    <>
      <Title>Arbetsställen</Title>
      <Panel compact padding="compact">
        {wps.map((wp) => (
          <StyledListItem
            key={wp.CFARNr}
            onClick={() => setActiveWp(wp)}
          >
            <StyledText theme={theme}>
              {titleCaseAB(wp['Företagsnamn'])}
            </StyledText>
          </StyledListItem>
        ))}
      </Panel>
    </>
  );

  if ((fetchState === fetchStates.FETCHED && workplaces.length === 0)
    || workplaceCount === 0) {
    return <>
      <Title>Arbetsställen</Title>
      <Panel compact padding="compact">
        <Text>Inga arbetsställen i fastigheten</Text>
      </Panel>
    </>;
  }

  if (fetchState !== fetchStates.FETCHED) {
    const btnText = typeof workplaceCount !== 'undefined'
      ? `Hämta ${workplaceCount} arbetsställen`
      : 'Hämta arbetsställen';
    return <>
      <Button
        disabled={!isCompleteAddress}
        isLoading={fetchState === fetchStates.FETCHING}
        appearance="primary"
        onClick={fetchWorkplaces}>{btnText}</Button>
    </>;
  }

  return typeof activeWp !== 'undefined'
    ? detailComponent(activeWp) : listComponent(workplaces);
};

export default Workplaces;
