const titleCaseAB = (str) => str.replace(/[\wåäöÅÄÖ]\S*[^AB]/g, (txt) => (
  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
));

const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.substr(1)}`;

const humanDistance = (meters) => {
  if (meters >= 10000) {
    return `${meters / 10000} mil`;
  }
  if (meters >= 1000) {
    return `${meters / 1000} km`;
  }
  return `${meters} m`;
};

const reduceAddressComponents = (place) => (
  place.address_components.reduce((acc, curr) => {
    curr.types.forEach((type) => {
      acc[type] = curr.long_name;
    });
    return acc;
  }, {})
);

export {
  titleCaseAB,
  capitalize,
  humanDistance,
  reduceAddressComponents,
};
