import React from 'react';
import PropTypes from 'prop-types';

import {
  Text,
  Panel,
  ListItem,
} from '@teron/fabric';

const Rents = ({ rents }) => {
  if (!rents) {
    return null;
  }

  return (
    <Panel
      compact
      padding="compact"
    >
      <ListItem>
        <Text
          appearance="primary"
          size="large"
          weight="medium"
        >
          {rents['värdeår 2016']} kr/m2
        </Text>
      </ListItem>
    </Panel>
  );
};

Rents.propTypes = {
  rents: PropTypes.shape({}),
};

export default Rents;
