import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '@teron/fabric';

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5em;
  background: ${(props) => props.theme.context.background.base};
  color: ${(props) => props.theme.context.background.contrast};
  border: none;
  border-radius: 3px;
`;

const TextField = React.forwardRef((props, ref) => {
  const {
    type,
    value,
    placeholder,
    onChange,
  } = props;

  const { theme } = useTheme('dark');

  return <>
    <StyledInput
      ref={ref}
      theme={theme}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      {...props} />
  </>;
});

TextField.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'password',
  ]),
};

export default TextField;
