import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';

import {
  Grid,
  Cell,
  Icon,
  useAuth,
} from '@teron/fabric';

import createClient from '../api';

import TextField from './TextField';
import InputButton from './InputButton';
import HitList from './HitList';

import { useSearchContext } from '../providers/SearchProvider';

const SearchForm = ({
  children,
  reset,
}) => {
  const { navigateTo, setPlaceId, setRegistryId } = useSearchContext();
  const { token } = useAuth();
  const client = createClient({ token });
  const textField = useRef();
  const [value, setValue] = useState('');
  const [data, setData] = useState([]);
  const [session, setSession] = useState(uuidv4());
  const [optionsKey, setOptionsKey] = useState('address');
  const [cancel, setCancel] = useState();
  const { registryId } = useParams();

  const optionsChoices = {
    address: {
      placeholder: 'Sök Adress',
      itemKey: 'place_id',
      itemTitleKey: 'description',
      clientFunc: client.placesAutocomplete,
      clickHandler: setPlaceId,
      searchCondition: (val) => val.length > 3,
    },
    designation: {
      placeholder: 'Sök Fastighetsbeteckning',
      itemKey: 'registerenhet',
      itemTitleKey: 'beteckning',
      clientFunc: client.propertiesSearch,
      clickHandler: navigateTo,
      searchCondition: (val) => val.indexOf(' ') > 0,
    },
  };

  const options = optionsChoices[optionsKey];

  const handleChoice = (item) => {
    setSession(uuidv4());
    setData([]);
    setValue('');
    options.clickHandler(item);
  };

  const handleOptionsChange = (key) => {
    setSession(uuidv4());
    setData([]);
    setValue('');
    setOptionsKey(key);
  };

  useEffect(() => {
    if (registryId) {
      setRegistryId(registryId);
    }
  }, [registryId]);

  useEffect(() => {
    const search = async () => {
      const res = await options.clientFunc(value, session);
      if (Array.isArray(res)) {
        setData(res);
      }
    };

    if (typeof cancel !== 'undefined') {
      cancel();
    }

    if (options.searchCondition(value)) {
      setCancel(() => client.cancel);
      search();
    } else if (data.length > 0) {
      setData([]);
    }
  }, [value]);

  const {
    top,
    left,
    width,
    height,
  } = textField.current ? textField.current.getBoundingClientRect() : {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  };

  if (React.Children.count(children)) {
    return (
      <Grid columns={12}>
        <Cell width={11}>
          {children}
        </Cell>
        <Cell style={{ justifySelf: 'end' }} width={1}>
          <Icon
            size="2rem"
            icon="highlight-off"
            onClick={reset} />
        </Cell>
      </Grid>
    );
  }

  return <>
    <TextField
      style={{ marginBottom: '1rem' }}
      ref={textField}
      type="text"
      placeholder={options.placeholder}
      value={value}
      onChange={(event) => setValue(event.target.value)} />
    <InputButton
      type="radio"
      label="Adress"
      checked={optionsKey === 'address'}
      onChange={() => handleOptionsChange('address')} />
    <InputButton
      type="radio"
      label="Fastighetsbeteckning"
      checked={optionsKey === 'designation'}
      onChange={() => handleOptionsChange('designation')} />
    {data.length !== 0
      && <HitList
        position={{ top: top + height, left }}
        width={width}
        items={data}
        itemKey={options.itemKey}
        itemTitleKey={options.itemTitleKey}
        onItemClick={(item) => handleChoice(item[options.itemKey])} />
    }
  </>;
};

SearchForm.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default SearchForm;
