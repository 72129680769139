import React from 'react';

import {
  Button,
  Title,
} from '@teron/fabric';

import Accordion from './Accordion';
import { usePoiContext } from '../providers/PoiProvider';

const Pois = () => {
  const {
    pois,
    fetchable,
    fetchPois,
    fetchState,
    fetchStates,
    activeType,
    setActiveType,
    activePoi,
    setActivePoi,
  } = usePoiContext();

  if (fetchState !== fetchStates.FETCHED) {
    return <>
      <Button
        disabled={!fetchable}
        isLoading={fetchState === fetchStates.FETCHING}
        appearance="primary"
        onClick={fetchPois}>Hämta platser i närheten</Button>
    </>;
  }

  return <>
    <Title>I närheten</Title>
    <Accordion
      sections={pois}
      activeSection={activeType}
      onSectionClick={(key) => setActiveType(key)}
      activeItem={activePoi}
      onItemClick={(key) => setActivePoi(key)}
    />
  </>;
};

export default Pois;
