import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import polyline from '@mapbox/polyline';

import { useAuth } from '@teron/fabric';

import createClient from '../api';
import { usePlaceContext } from './PlaceProvider';
import { usePoiContext } from './PoiProvider';

const Context = createContext();

const distStates = {
  NOT_CALCULATED: 'notCalculated',
  CALCULATE: 'calculate',
  CALCULATING: 'calculating',
  CALCULATED: 'calculated',
};

const initialState = {
  parking: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  subway_station: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  bus_station: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  train_station: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  restaurant: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  gym: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  lodging: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  school: {
    state: distStates.NOT_CALCULATED,
    mode: 'walking',
    translation: 'till fots',
    distances: [],
  },
  airport: {
    state: distStates.NOT_CALCULATED,
    mode: 'driving',
    translation: 'i bil',
    distances: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'STATE_TRANSITION': {
      const { poiType, targetState } = action;

      return {
        ...state,
        [poiType]: {
          ...state[poiType],
          state: targetState,
        },
      };
    }
    case 'ADD_DISTANCES': {
      const { poiType, distances } = action;

      return {
        ...state,
        [poiType]: {
          ...state[poiType],
          distances,
        },
      };
    }

    case 'RESET_DISTANCES': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const DistanceProvider = ({ children }) => {
  const { token } = useAuth();
  const client = createClient({ token });
  const { place } = usePlaceContext();
  const { pois } = usePoiContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const calculateDistance = (poiType) => dispatch({
    type: 'STATE_TRANSITION',
    poiType,
    targetState: distStates.CALCULATE,
  });
  const calculatingDistance = (poiType) => dispatch({
    type: 'STATE_TRANSITION',
    poiType,
    targetState: distStates.CALCULATING,
  });
  const calculatedDistance = (poiType) => dispatch({
    type: 'STATE_TRANSITION',
    poiType,
    targetState: distStates.CALCULATED,
  });
  const addDistances = (poiType, distances) => dispatch({
    type: 'ADD_DISTANCES',
    poiType,
    distances,
  });
  const resetDistances = () => dispatch({
    type: 'RESET_DISTANCES',
  });

  useEffect(() => {
    const getDistanceMatrix = async (type) => {
      calculatingDistance(type);
      const destinations = pois[type].places.map((pl) => (
        [pl.geometry.location.lat, pl.geometry.location.lng]
      ));
      const encDestinations = polyline.encode(destinations);
      const data = await client.placesDistancematrix(
        `place_id:${place.place_id}`,
        `enc:${encDestinations}:`,
        state[type].mode,
      );
      addDistances(type, data.rows[0].elements);
      calculatedDistance(type);
    };

    const typeToCalculate = Object.keys(state).find((key) => (
      state[key].state === distStates.CALCULATE
    ));

    if (typeof typeToCalculate !== 'undefined') {
      getDistanceMatrix(typeToCalculate);
    }
  }, [state]);

  return (
    <Context.Provider
      value={{
        distances: state,
        calculateDistance,
        resetDistances,
        distStates,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useDistanceContext = () => useContext(Context);

export default DistanceProvider;
export { useDistanceContext };
