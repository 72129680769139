import React, { useState } from 'react';

import {
  Panel,
  Title,
  Text,
} from '@teron/fabric';

import InputButton from './InputButton';
import { useMapContext } from '../providers/MapProvider';

const MapLayers = () => {
  const { layers, selectedLayers, setSelectedLayers } = useMapContext();

  const handleLayerChange = (layer) => {
    if (selectedLayers.includes(layer)) {
      layers[layer].hide();
      setSelectedLayers(selectedLayers.filter((key) => key !== layer));
    } else {
      layers[layer].show();
      setSelectedLayers([...selectedLayers, layer]);
    }
  };

  const createLabel = (layer) => <>
    <Text block={false}>{layer.name} </Text>
    <Text
      block={false}
      appearance="textFaint"
      size="small"
    >
      {layer.source}
    </Text>
  </>;

  return <>
    <Title>Kartlager</Title>
    <Panel compat padding="compact">
      {Object.keys(layers).map((key) => (
        <InputButton
          key={key}
          type="checkbox"
          inline={false}
          label={createLabel(layers[key])}
          checked={selectedLayers.includes(key)}
          onChange={() => handleLayerChange(key)} />
      ))}
    </Panel>
  </>;
};

export default MapLayers;
