import React, {
  createContext,
  useState,
  useContext,
} from 'react';

import { useHistory } from 'react-router-dom';

const Context = createContext({});

const SearchProvider = ({ children }) => {
  const [address, setAddress] = useState();
  const [placeId, setPlaceId] = useState();
  const [registryId, setRegistryId] = useState();
  const history = useHistory();

  const resetSearch = () => {
    setPlaceId();
    setAddress();
    setRegistryId();
    history.push('/');
  };

  const navigateTo = (id) => {
    history.push(`/${id}`);
  };

  return (
    <Context.Provider
      value={{
        navigateTo,
        registryId,
        setRegistryId,
        placeId,
        setPlaceId,
        address,
        setAddress,
        resetSearch,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useSearchContext = () => useContext(Context);

export default SearchProvider;
export { useSearchContext };
