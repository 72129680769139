import React from 'react';

import { Title } from '@teron/fabric';

import Select from './Select';
import Rents from './Rents';

import { useRentsContext } from '../providers/RentsProvider';

const ValueAreas = () => {
  const {
    valueAreas,
    setValueArea,
    rents,
  } = useRentsContext();

  return <>
    <Title>Genosnittshyror</Title>
    <Select
      placeholder="Välj ett värdeområde"
      options={valueAreas}
      valueKey="code"
      textKey="name"
      margin="compact"
      onChange={setValueArea} />
    {rents && <Rents rents={rents} />}
  </>;
};

export default ValueAreas;
