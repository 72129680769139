import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import {
  Container,
  Text,
  Panel,
  ListItem,
  Tooltip,
  Icon,
  useTheme,
} from '@teron/fabric';

import { capitalize } from '../utils';
import Marker from './Marker';
import { useDistanceContext } from '../providers/DistanceProvider';

const StyledListItem = styled(ListItem)`
  align-items: baseline;
  cursor: ${(props) => (typeof props.onClick !== 'undefined' ? 'pointer' : 'inherit')};
`;

const titleStyle = (theme, isActive) => css`
  cursor: pointer;
  color: ${isActive ? theme.context.primary.base : theme.context.text.base};
  &:hover {
    color: ${theme.context.primary.base};
  }
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.context.primary.base};
  margin-left: ${(props) => props.theme.spacing.compact};
`;

const AccordionSection = ({
  sectionKey,
  title,
  subTitle,
  items,
  activeSection,
  onSectionClick,
  activeItem,
  onItemClick,
}) => {
  const { theme } = useTheme();
  const {
    distances,
    calculateDistance,
    distStates,
  } = useDistanceContext();

  const handleSectionClick = () => {
    if (!!activeSection && activeSection === sectionKey) {
      onSectionClick(null);
    } else {
      onSectionClick(sectionKey);
    }
    onItemClick(null);
  };

  const handleItemClick = (key) => {
    if (!!activeItem && activeItem === key) {
      onItemClick(null);
    } else {
      onItemClick(key);
    }
  };

  const itemCountStr = items.length === 20 ? `>${items.length} st`
    : `${items.length} st`;

  const afterComp = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text block={false} appearance="textMuted">{itemCountStr}</Text>
      {distances[sectionKey].state === distStates.NOT_CALCULATED
        && <Tooltip content="Räkna ut avstånd">
          <StyledIcon
            theme={theme}
            icon="explore"
            size="1.2rem"
            onClick={() => calculateDistance(sectionKey)} />
        </Tooltip>
      }
    </div>
  );

  const isActive = !!activeSection && activeSection === sectionKey;

  return <>
    <Container
      key={sectionKey}
      margin="compact"
    >
      <Panel
        compact
        padding="compact"
      >
        <StyledListItem
          key="title"
          inline
          subTitle={subTitle}
          before={isActive ? <Marker centered={false} /> : null}
          after={afterComp}
        >
          <Text
            css={titleStyle(theme, isActive)}
            weight="medium"
            onClick={handleSectionClick}
          >
            {capitalize(title)}
          </Text>
        </StyledListItem>
        {isActive && items.map((item, i) => (
          <StyledListItem
            key={item.place_id}
            before={!!activeItem && activeItem === item.place_id
              ? <Marker active centered={false} /> : null}
            after={typeof distances[sectionKey].distances[i] !== 'undefined'
              && distances[sectionKey].distances[i].distance.text}
            subTitle={item.vicinity}
            onClick={() => handleItemClick(item.place_id)}
          >
            {item.name}
          </StyledListItem>
        ))}
      </Panel>
    </Container>
  </>;
};

AccordionSection.propTypes = {
  sectionKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  items: PropTypes.array.isRequired,
  activeSection: PropTypes.string,
  onSectionClick: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
};

export default AccordionSection;
