import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {
  Panel,
  Portal,
  ListItem,
} from '@teron/fabric';

const StyledListItem = styled(ListItem)`
  cursor: pointer;
`;

const StyledList = styled.div`
  z-index: 100;
  position: absolute;
  top: ${(props) => props.position.top}px;
  left: ${(props) => props.position.left}px;
  width: ${(props) => props.width}px;
`;

const HitList = ({
  position,
  width,
  items,
  itemKey,
  itemTitleKey,
  onItemClick,
}) => {
  const wrapperEl = useRef();
  const [visible, setVisible] = useState(true);

  const handleDocClick = (e) => {
    if (!wrapperEl.current || wrapperEl.current.contains(e.target)) {
      return;
    }
    setVisible(false);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 27) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocClick);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('mousedown', handleDocClick, false);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    setVisible(true);
  }, [items]);

  if (!visible) {
    return null;
  }

  return <>
    <Portal>
      <div ref={wrapperEl}>
        <StyledList
          position={position}
          width={width} >
          <Panel backdrop="dark">
            {items.map((item) => (
              <StyledListItem
                key={item[itemKey]}
                onClick={() => onItemClick(item)}>
                {item[itemTitleKey]}
              </StyledListItem>
            ))}
          </Panel>
        </StyledList>
      </div>
    </Portal>
  </>;
};

HitList.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  items: PropTypes.array,
  itemKey: PropTypes.string.isRequired,
  itemTitleKey: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

HitList.defaultProps = {
  items: [],
  position: { top: 0, left: 0 },
};

export default HitList;
