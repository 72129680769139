import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import GoogleMapReact from 'google-map-react';

import {
  DonutMarker,
  Overlay,
  ListItem,
} from '@teron/fabric';


import Marker from './Marker';
import { useMapContext } from '../providers/MapProvider';
import { usePoiContext } from '../providers/PoiProvider';
import { usePlaceContext } from '../providers/PlaceProvider';

const StyledDonutMarker = styled(DonutMarker)`
  transform: translate(-50%, -50%);
`;

const StyledOverlay = styled(Overlay)`
  position: absolute;
  cursor: inherit;
  background: #f8f8f8bb;
`;

const Map = () => {
  const { googleMaps, setGoogleMaps, mapRef } = useMapContext();
  const [markers, setMarkers] = useState();
  const { place } = usePlaceContext();
  const {
    pois,
    activeType,
    activePoi,
    setActivePoi,
  } = usePoiContext();

  const handlePoiClick = (id) => {
    if (!!activePoi && activePoi === id) {
      setActivePoi(null);
    } else {
      setActivePoi(id);
    }
  };

  const handleApiLoaded = (map, maps) => {
    mapRef.current = map;
    setGoogleMaps(maps);
  };

  useEffect(() => {
    const createPoiMarkers = () => {
      const markerComps = [];
      pois[activeType].places.forEach((poi) => {
        const tooltipComp = (
          <ListItem
            subTitle={poi.vicinity}
          >{poi.name}</ListItem>
        );
        markerComps.push(
          <Marker
            key={poi.place_id}
            lat={poi.geometry.location.lat}
            lng={poi.geometry.location.lng}
            active={!!activePoi && activePoi === poi.place_id}
            onClick={() => handlePoiClick(poi.place_id)}
            tooltipContent={tooltipComp} />
        );
      });

      // Active marker should always be last in the DOM.
      // That way the tooltip is never obscured by other markers.
      return markerComps.sort((a, b) => {
        if (a.props.active === b.props.active) {
          return 0;
        }
        return a.props.active ? 1 : -1;
      });
    };

    const placeMarker = (
      <StyledDonutMarker
        key="property"
        value={100}
        appearance="danger"
        lat={place.geometry.location.lat}
        lng={place.geometry.location.lng} />
    );

    if (place.dirty) {
      setMarkers();
    } else if (activeType) {
      const _markers = createPoiMarkers();
      _markers.unshift(placeMarker);
      setMarkers(_markers);
    } else {
      setMarkers([placeMarker]);
    }
  }, [place, activeType, activePoi]);

  useEffect(() => {
    const fitBounds = () => {
      const bounds = new googleMaps.LatLngBounds();
      bounds.extend(place.geometry.location);

      pois[activeType].places.forEach((poi) => {
        bounds.extend(poi.geometry.location);
      });

      mapRef.current.fitBounds(bounds);
    };

    if (googleMaps && activeType) {
      fitBounds();
    }
  }, [activeType]);

  return <>
    <div style={{ position: 'relative', height: '100%' }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCIsIyYV7wIw026XQOaNvmKbTE-cauVS3s' }}
          center={place.geometry.location}
          zoom={15}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {markers}
        </GoogleMapReact>
        {place.dirty && <StyledOverlay />}
      </div>
    </div>
  </>;
};

export default Map;
