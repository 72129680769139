import axios from 'axios';
import qs from 'qs';

const createClient = ({
  baseURL = 'https://mogulapi.trell.tech',
  token,
  debug = true,
}) => {
  const source = axios.CancelToken.source();
  const client = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // By default, axios (or some underlying dep) substitutes ' ' with '+'.
    // This is not preferrable in some cases. We want a proper '%20'.
    paramsSerializer: (params) => (
      qs.stringify(params, { arrayFormat: 'brackets' })
    ),
    cancelToken: source.token,
  });

  client.interceptors.request.use((request) => {
    if (debug) {
      console.log(request);
    }
    return request;
  });

  const request = async (config) => {
    try {
      const { data } = await client(config);
      return data;
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled', e.message);
      } else {
        console.log('error', e);
        throw Error('client error');
      }
      return {};
    }
  };

  const get = (url, params) => (
    request({
      method: 'get',
      url,
      params,
    })
  );

  return {
    cancel: source.cancel,
    placesDetail: async (placeId) => (
      get(`/places/${placeId}/`)
    ),
    placesAutocomplete: async (input, session) => (
      get('/places/autocomplete/', { input, session })
    ),
    placesGeocode: async (address) => (
      get('/places/geocode/', { address })
    ),
    placesNearby: async (location, type, radius) => (
      get('/places/nearby/', { location, type, radius })
    ),
    placesDistancematrix: async (origins, destinations, mode) => (
      get('/places/distancematrix/', { origins, destinations, mode })
    ),
    workplacesCount: async (address, postal_town) => (
      get('/workplaces/count/', { address, postal_town })
    ),
    workplaces: async (address, postal_town) => (
      get('/workplaces/', { address, postal_town })
    ),
    valueAreas: async (municipality_code) => (
      get('/value-areas/', { municipality_code })
    ),
    rents: async (value_area) => (
      get('/rents/', { value_area })
    ),
    properties: async (address) => (
      get('/properties/', { address })
    ),
    propertiesDetail: async (registryId) => (
      get(`/properties/${registryId}/`)
    ),
    propertiesSearch: async (query) => (
      get('/properties/search/', { query })
    ),
    propertiesAddresses: async (registryId) => (
      get(`/properties/${registryId}/addresses/`)
    ),
  };
};

export default createClient;
