import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useAuth } from '@teron/fabric';

import { usePropertyContext } from './PropertyProvider';
import createClient from '../api';

const Context = createContext({});

const fetchStates = {
  NOT_FETCHED: 'notFetched',
  FETCH: 'fetch',
  FETCHING: 'fetching',
  FETCHED: 'fetched',
};

const WorkplaceProvider = ({ children }) => {
  const { token } = useAuth();
  const client = createClient({ token });
  const { addresses } = usePropertyContext();
  const [workplaceCount, setWorkplaceCount] = useState();
  const [fetchState, setFetchState] = useState(fetchStates.NOT_FETCHED);
  const [workplaces, setWorkplaces] = useState([]);
  const [activeWp, setActiveWp] = useState();

  const fetchWorkplaces = () => setFetchState(fetchStates.FETCH);

  const resetWorkplaces = () => {
    setWorkplaces([]);
    setWorkplaceCount();
    setActiveWp();
    setFetchState(fetchStates.NOT_FETCHED);
  };

  const workplaceKeys = [
    'Företagsnamn',
    'OrgNr',
    'BesöksAdress',
    'BesöksPostOrt',
    'Län',
    'Storleksklass',
    'Bransch_1',
    'Bransch_2',
    'Antal arbetsställen',
    'Sektor',
    'Juridisk form',
    'Privat/Publikt',
    'Arbetsställetyp',
    'Huvudarbetsställe enl. EU',
  ];

  const addrStrs = useMemo(() => {
    if (addresses.length !== 0) {
      return addresses.map((address) => (
        ['route', 'street_number'].map((key) => address[key]).join(' ')
      ));
    }
    return undefined;
  }, [addresses]);

  useEffect(() => {
    const getWorkplacesCount = async () => {
      const { postal_town } = addresses[0];
      const count = await addrStrs.reduce(async (acc, addrStr) => {
        const data = await client.workplacesCount(addrStr, postal_town);
        return await acc + Number(data);
      }, 0);
      setWorkplaceCount(count);
    };

    if (addresses.length !== 0) {
      getWorkplacesCount();
    }
  }, [addresses]);

  useEffect(() => {
    const getWorkplaces = async () => {
      setFetchState(fetchStates.FETCHING);

      const { postal_town } = addresses[0];
      const accData = await addrStrs.reduce(async (acc, addrStr) => {
        const data = await client.workplaces(addrStr, postal_town);
        return [...await acc, ...data];
      }, []);
      setWorkplaces(accData);

      setFetchState(fetchStates.FETCHED);
    };

    if (fetchState === fetchStates.FETCH) {
      getWorkplaces();
    }
  }, [fetchState]);

  return (
    <Context.Provider
      value={{
        fetchState,
        fetchStates,
        fetchWorkplaces,
        workplaceCount,
        workplaces,
        workplaceKeys,
        activeWp,
        setActiveWp,
        resetWorkplaces,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useWorkplaceContext = () => useContext(Context);

export default WorkplaceProvider;
export { useWorkplaceContext };
