import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import {
  useTheme,
  Backdrop,
} from '@teron/fabric';

const style = ({
  theme,
  appearance,
  centered,
  clickable,
}) => css`
  font-family: ${theme.typography.normal.family};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${theme.context[appearance].base};
  transform: ${centered ? 'translate(-50%, -50%)' : 'none'};
  cursor: ${clickable ? 'pointer' : 'inherit'};
`;

const StyledTooltipInner = styled(Backdrop)`
  display: inline-block;
  max-width: 200px;
  padding: ${(props) => props.theme.spacing.compact};
  box-shadow: ${(props) => (props.theme.elevation.standard)};
  border-radius: 0.2rem;
  text-align: left;
  line-height: 1.3;
`;

const StyledTooltip = styled.div`
  pointer-events: none;
  font-size: 1rem;
  word-wrap: break-word;
  width: 200px;
  z-index: 1;
  text-align: center;
  position: absolute;
  transition: opacity 150ms ease-in-out;
  transform: translate(calc(-50% + 5px), calc(-100% - 0.5rem));
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const Marker = ({
  active,
  centered = true,
  onClick,
  tooltipContent,
}) => {
  const { theme } = useTheme();
  const markerEl = useRef();

  const withTooltip = typeof tooltipContent !== 'undefined';

  return <>
    <div
      ref={markerEl}
      css={style({
        theme,
        appearance: active ? 'danger' : 'primary',
        centered,
        clickable: typeof onClick !== 'undefined',
      })}
      onClick={onClick}
    >
      {withTooltip
        && <StyledTooltip
          visible={active}
          theme={theme}
        >
          <StyledTooltipInner
            theme={theme}
            backdrop="overlay"
          >
            {tooltipContent}
          </StyledTooltipInner>
        </StyledTooltip>
      }
    </div>
  </>;
};

export default Marker;
