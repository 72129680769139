import React, { useMemo } from 'react';

import {
  Title,
  Text,
  Panel,
  ListItem,
} from '@teron/fabric';

import { usePropertyContext } from '../providers/PropertyProvider';

const Addresses = () => {
  const { addresses } = usePropertyContext();

  const addrStrs = useMemo(() => (
    addresses
      .map((address) => ['route', 'street_number', 'letter']
        .filter((key) => typeof address[key] !== 'undefined')
        .map((key) => address[key])
        .join(' '))
      .sort()
  ), [addresses]);

  return <>
    <Title>Adresser</Title>
    <Panel
      compact
      padding="compact"
    >
      {addrStrs.map((addrStr) => (
        <ListItem key={addrStr}>
          <Text weight="normal">
            {addrStr}
          </Text>
        </ListItem>
      ))}
    </Panel>
  </>;
};

export default Addresses;
